export default [
  {
    name: 'Home',
    url: '/',
    type: 'internal',
  },
  {
    name: 'Podcast',
    url: 'https://podcast.unfilteredbuild.com',
    type: 'external',
  },
];
